<template>
  <div class="Figure">
    <HeaderHot :title="'资本人物'" :currentIndex="2"></HeaderHot>
    <Breadcrumb :navTitle="'资本人物'"></Breadcrumb>
    <div class="Figure-content">
      <div class="Figure-left">
        <img :src="BaseUrl + itemData.topList[0].image" alt="" />
        <div class="left-content">
          <div
            class="left-item"
            v-for="item in itemList"
            :key="item.id"
            @click="goFigureDetails(item.id)"
          >
            <img :src="BaseUrl + item.image" alt="" />
            <div>
              <span>{{ item.title }}</span>
              <div>
                <span :title="item.brief">{{ item.brief }}</span>
                <span>[详细]</span>
              </div>
              <span>{{ item.createtime }}</span>
            </div>
          </div>

          <div class="pageDown">
            <el-pagination
              background
              prev-text="上一页"
              next-text="下一页"
              @prev-click="pagePrev"
              @next-click="pageNext"
              @current-change="pageChange"
              layout=" prev, pager, next"
              page-size="5"
              :total="totalPage"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="Figure-right">
        <div class="right-one">
          <span>{{ itemData.topList[0].title }}</span>
          <div>
            <span>{{ itemData.topList[0].brief }}</span>
            <span @click="goFigureDetails(itemData.topList[0].id)">[详细]</span>
          </div>
          <div>
            <span
              v-for="item in itemData.topList.slice(1, 8)"
              :key="item.id"
              :title="item.title"
              @click="goFigureDetails(item.id)"
              >{{ item.title }}</span
            >
          </div>
        </div>
        <div class="right-two">
          <div class="right-tit">
            <span>精选</span>
          </div>
          <div class="two-list">
            <div
              class="list-item"
              v-for="item in itemData.tuiList"
              @click="goFigureDetails(item.id)"
              :key="item.id"
            >
              <div>
                <img :src="BaseUrl + item.image" alt="" />
                <span :title="item.title">{{ item.title }}</span>
              </div>
              <div>
                <span :title="item.brief">{{ item.brief }}</span>
                <span>[详细]</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderHot from "../../../components/HeaderHot.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import Footer from "../../../components/Footer.vue";

export default {
  name: "Figure",
  data() {
    return {
      page: 1,
      pageSize: 5,
      totalPage: "",
      itemData: [],
      itemList: [],
    };
  },
  created() {
    this.getData();
    this.getList();
  },
  methods: {
    // 获取资本人物页面数据
    getData() {
      this.$http({
        url: "/anli/index",
      }).then((res) => {
        console.log(res, "res");
        this.itemData = res.data;
      });
    },
    // 获取资本人物列表
    getList(page) {
      this.$http({
        url: "/anli/list",
        params: {
          page: page ? page : this.page,
          pagesize: this.pageSize,
        },
      }).then((res) => {
        console.log(res, "res");
        this.itemList = res.data.list;
        this.totalPage = res.data.total;
      });
    },
    // 上一页
    pagePrev() {
      this.page--;
      this.getList(this.page);
    },
    // 下一页
    pageNext() {
      console.log("下一页");
      this.page++;
      this.getList(this.page);
    },
    // 页面改变
    pageChange(e) {
      console.log(e);
      this.page = e;
      this.getList(this.page);
    },
    // 跳转资本人物详情
    goFigureDetails(id) {
      this.$router.push(`/figuredetails/${id}`);
    },
  },
  components: {
    HeaderHot,
    Breadcrumb,
    Footer,
  },
};
</script>

<style lang="less" scoped>
.Figure {
  .Figure-content {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    .Figure-left {
      margin-right: 40px;
      > img:nth-child(1) {
        width: 750px;
        height: 450px;
        margin-bottom: 60px;
      }
      .left-content {
        .left-item {
          display: flex;
          width: 750px;
          height: 234px;
          margin-bottom: 49px;
          border-bottom: 1px dashed #a6a6a6;
          > img:nth-child(1) {
            width: 310px;
            height: 193px;
            margin-right: 36px;
          }
          > div:nth-child(2) {
            width: 424px;
            display: flex;
            flex-direction: column;
            > span:nth-child(1) {
              font-size: 17px;
              color: #333333;
            }
            > span:nth-child(1):hover {
              text-decoration: underline;
            }
            > div:nth-child(2) {
              margin: 20px 0 27px 0;
              > span:nth-child(1) {
                font-size: 16px;
                color: #666666;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                -webkit-box-orient: vertical;
              }
              > span:nth-child(2) {
                font-size: 16px;
                color: #2050ac;
                cursor: pointer;
              }
            }
            > span:nth-child(3) {
              font-size: 14px;
              color: #999999;
            }
          }
        }
        .pageDown {
          width: 750px;
          margin: 80px auto 30px;
          text-align: center;
        }
      }
    }
    .Figure-right {
      width: 409px;
      .right-one {
        margin-bottom: 48px;
        > span:nth-child(1) {
          font-size: 24px;
          color: #333333;
          margin-top: 20px;
        }
        > span:nth-child(1):hover {
          text-decoration: underline;
        }
        > div:nth-child(2) {
          margin: 22px 0 38px 0;
          height: 76px;
          border-bottom: 1px dashed #a6a6a6;
          > span:nth-child(1) {
            font-size: 16px;
            color: #333333;
          }
          > span:nth-child(2) {
            font-size: 16px;
            color: #2050ac;
            cursor: pointer;
          }
        }
        > div:nth-child(3) {
          display: flex;
          flex-direction: column;
          font-size: 16px;
          color: #333333;
          > span {
            margin-bottom: 17px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
          }
          > span::before {
            display: inline-block;
            content: "";
            width: 4px;
            height: 4px;
            background: #bd1326;
            margin: 0 6px 3px 0;
            border-radius: 50%;
          }
          span:hover {
            text-decoration: underline;
          }
        }
      }

      .right-two {
        .right-tit {
          width: 409px;
          height: 38px;
          margin-bottom: 40px;
          border-bottom: 2px solid #a6a6a6;
          > span {
            display: block;
            width: 72px;
            height: 38px;
            font-size: 17px;
            color: #333333;
            text-align: center;
            border-bottom: 2px solid #12407d;
          }
        }
        .two-list {
          .list-item {
            margin-bottom: 49px;
            > div:nth-child(1) {
              position: relative;
              width: 409px;
              height: 234px;
              > img:nth-child(1) {
                position: absolute;
                width: 409px;
                height: 234px;
              }
              > span:nth-child(2) {
                width: 409px;
                height: 40px;
                position: absolute;
                bottom: 0;
                text-align: center;
                line-height: 40px;
                background: rgba(100, 100, 100, 0.33);
                color: #ffffff;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-indent: 0.5em;
              }
            }
            > div:nth-child(2) {
              margin-top: 22px;
              font-size: 14px;
              color: #666666;

              > span:nth-child(1) {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                -webkit-box-orient: vertical;
              }
              > span:nth-child(2) {
                font-size: 16px;
                color: #2050ac;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
</style>
